import '../../assets/css/style.css'
import React, { useState } from "react"
import { useNavigate} from "react-router-dom";
import swal from 'sweetalert';
function Login(){
    const[username, setusername] = useState("");
    const[password, setPassword] = useState("")
    const [error, setError] = useState("")
    // const [store, setStore] = useState([])
    // const [succes, setSucess] = useState([])
    const navigate = useNavigate();
   
    async function SignIn(){
        let item = {username,password};
        let result = await fetch("https://nimbacoin.com/nimbacoin_backend/api/login",{
            method:"POST",
            headers :{
                "Content-Type" : "application/json",
                "Accept" : "application/json"
            },
            body:JSON.stringify(item)
        })
            result = await result.json()
            
            // setStore(result.code)
            // setSucess(result.message)
           
            setError("");
            if (result.err) {
               
                swal( result.err );
                console.log('no')
            }
            else{
                console.log(result.token)
                localStorage.setItem("token", JSON.stringify(result.token))
                navigate('/dashboard')
            }
             
    
         
      
    
    }
    return(
        <> 
        <div class="account__area">
            <div class="account-bg"></div>
            <div class="container">
                <div class="account__content">
                    <div class="account__form">
                        <div class="account__title mb-29">
                            <h2>log In</h2>
                        </div>
                            <div class="input__group mb-23">
                                <label>Username</label>
                                <input type="text" name="username" onChange={(e)=>setusername(e.target.value)} placeholder="Enter your Username" />
                                <b className="text-danger text-left">{error.username}</b> 
                            </div>
                            <div class="input__group mb-23">
                                <label>Password</label>
                                <input type="password" name="password" onChange={(e)=>setPassword(e.target.value)} placeholder="Enter your password" />
                                <b className="text-danger text-left">{error.password}</b> 
                            </div>
                            <div class="item-button">
                                <button class="theme-btn blue" onClick={SignIn}>
                                    <span>Sign in</span>
                                    <div class="hover-shape1"></div>
                                    <div class="hover-shape2"></div>
                                    <div class="hover-shape3"></div>
                                </button>
                            </div>
                    </div>
                    <div class="account-shadow"></div>
                </div>
            </div>
        </div>

        
        </>
    )
}
export default Login