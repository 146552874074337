import React, { useState } from "react";
// import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";

function Communtiy() {
    const [busd, setBusd] = useState("");
    const [telegram_id, setTelegram_id] = useState("");
    const [twitter_id, setTwitter_id] = useState("");
    const [email, setEmail] = useState("");
    const [bnb, setBnb] = useState("");
    const [retweet_link, setRetweet_link] = useState("");
    const navigate = useNavigate();
    // const [eroor, seterror] = useState("");
    async function ContactUs() {
     
        let data={ busd, telegram_id, twitter_id, email, bnb,retweet_link }
        // console.log(data);

    
        let result = await fetch("https://nimbacoin.com/nimbacoin_backend/api",{
          method: "POST",
          headers: {
            "Content-Type":"application/x-www-form-urlencoded",
            "Accept":"application/json",
            "Access-Control-Allow-Origin":"true"
          },
          body:JSON.stringify(data),
        }) 
        if (result.status === 200) {
    
          result = await result.json();
          console.log(result.success)
          // swal({
          //   title: "Thank You",
          //   text: "Thanks for contacting us, we will revert back soon.",
          //   icon: "success",
          //   button: "Ok!",
          // });
          
          navigate('/fthanks')
          setBusd("");
          setTelegram_id("");
          setTwitter_id("");
          setEmail("");
          setBnb("");
          setRetweet_link("");
        }
    
        else {
          result = await result.json();
             }
            
      }
      
  return (
    <>
      <section className="apply-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 m-auto">
              <p>La vente privée de NimbaCoin s'ouvrira le dimanche 01 Janvier  2023 à 00h00 CET et se terminera à la même heure le 31 Mars 2023.</p>
              <ul>
                <li>* Pour y participer soumettez votre demande  en remplissant ce FORMULAIRE.</li>
                <li>* L'inscription sur le formulaire durera jusqu'à la fin officielle de la vente privée. Il n'y a pas de restrictions de pays ou d'exigences KYC pour participer à la vente privée.</li>
              </ul>
              <h2 className="mt-5">DÉtails sur la loterie de la vente privÉe :
</h2>
<p>Tous les participants à la prévente sont éligibles pour participer à la loterie Whitelist. Nous visons à créer une opportunité équitable de participer au projet NimbaCoin/<a href='https://www.nimbatube.com/' rel='noreferrer noopener' target='_blank'> NimbaTube.com</a> et avons donné un ensemble spécifique d'exigences qui doivent être remplies afin de se qualifier pour la loterie Whitelist qui sont les suivantes:
</p>
              <p>Les participants doivent :</p>
              <ul>
                <li>* 1 . Remplir le formulaire de vente privée pour acheter au moins 1000 NIMBACOINS</li>
                <li>
                * 2. Nous suivre sur Twitter :
                  <a href="https://twitter.com/nimbacoin/" rel='noreferrer noopener' target='_blank'>https://twitter.com/nimbacoin/</a>
                </li>
                <li>
                * 3. Retweeter notre annonce de vente privée épinglée sur Twitter en citant également le hashtag #NimbaCoin et indiquer  pourquoi ils sont ravis de participer à la vente privée toute en  taguant deux autres amis dans leur retweet.
                </li>
                <li>
                * 4. Rejoindre notre communauté Telegram:  <a href="https://t.me/officialnimbacoin" rel='noreferrer noopener' target='_blank' >https://t.me/officialnimbacoin </a> <br />Toutes les listes seront vérifiées sous la forme d'adresses BEP-20, de hashtag de telegram et de comptes Twitter. <br />
                Toute inscription qui n'a pas terminé toutes les étapes ci-dessus sera disqualifiée. Nous sélectionnerons au hasard les participants finaux à la loterie Vente Privée parmi ces entrées de liste et annoncerons les gagnants peu de temps après.</li>
              </ul>
              <h2 className="mt-5">Rejoignez notre communautÉ</h2>
              <ul>
                <li>
                * Site Web :
                  <a href="https://nimbacoin.com/" target='_blank' rel='noreferrer noopener'>https://nimbacoin.com/</a>{" "}
                </li>
                <li>
                * Chaine Telegram :  <a href="https://t.me/officialnimbacoin" target='_blank' rel='noreferrer noopener'>https://t.me/officialnimbacoin</a>{" "}
                </li>
                <li>
                *  Groupe de discussion Telegram : <a href="https://t.me/nimbaddict" target='_blank' rel='noreferrer noopener'>https://t.me/nimbaddict</a>{" "}
                </li>
                <li>
                * Twitter :
                  <a href="https://twitter.com/nimbacoin/" target='_blank' rel='noreferrer noopener'>
                    {" "}
                    https://twitter.com/nimbacoin/
                  </a>
                </li>
                <li>
                *   Discord :<a href="https://discord.gg/xmTaU8A9/" target='_blank' rel='noreferrer noopener'>https://discord.gg/xmTaU8A9/</a>
                </li>
                <li>
                * Platform :
                  <a href="http://www.nimbatube.com/" target='_blank' rel='noreferrer noopener'>
                    {" "}
                    http://www.nimbatube.com/
                  </a>
                </li>
                <li>
                * Email :
                  <a href="mailto:contact@nimbacoin.com" target='_blank' rel='noreferrer noopener'>
                    {" "}
                    contact@nimbacoin.com
                  </a>
                </li>
              </ul>
              <div className="form-section">
                <div className="input__group input__group--v2 mb-23">
                  <label>Email *</label>
                  <input
                    type="text"
                    name="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    placeholder="votre réponse"
                    required=""
                  />
                
                </div>
                <div className="input__group input__group--v2 mb-23">
                  <label>IDENTIFIANT TWITTER : *</label>
                  <input
                    type="text"
                    name="twitter_id"
                    value={twitter_id}
                    onChange={(e) => {
                      setTwitter_id(e.target.value);
                    }}
                    placeholder="votre réponse"
                    required=""
                  />
                 
                </div>
                <div className="input__group input__group--v2 mb-23">
                  <label>VOTRE ADRESSE BNB BEP-20 POUR PARTICIPER À LA VENTE PRIVÉE ? *REMARQUE : VEUILLEZ NE PAS UTILISER  D'ADRESSE D'ÉCHANGEUR TEL QUE BINANCE ETC.</label>
                  <input
                    type="text"
                    name="bnb"
                    value={bnb}
                    onChange={(e) => {
                      setBnb(e.target.value);
                    }}
                    placeholder="votre réponse"
                    required=""
                  />
                 
                </div>
                <div className="input__group input__group--v2 mb-23">
                  <label>ID TELEGRAM *</label>
                  <input
                    type="text"
                    name="telegram_id"
                    value={telegram_id}
                    onChange={(e) => {
                      setTelegram_id(e.target.value);
                    }}
                    placeholder="votre réponse"
                    required=""
                  />
                 
                </div>
                <div className="input__group input__group--v2 mb-23">
                  <label>COMBIEN SOUHAITEZ-VOUS INVESTIR (BUSD ) ? *</label>
                  <input
                    type="text"
                    name="busd"
                    value={busd}
                    onChange={(e) => {
                      setBusd(e.target.value);
                    }}
                    placeholder="votre réponse"
                    required=""
                  />
                 
                </div>
                <div className="input__group input__group--v2 mb-23">
                  <label>VEUILLEZ ENTRER VOTRE LIEN RETWEET TWITTER DE NOTRE ANNONCE DE VENTE PRIVÉE ÉPINGLÉE ? *</label>
                  <input
                    type="text"
                    name="retweet_link"
                    value={retweet_link}
                    onChange={(e) => {
                      setRetweet_link(e.target.value);
                    }}
                    placeholder="votre réponse"
                    required=""
                  />
                 
                </div>
                <button className="theme-btn blue w-100" onClick={ContactUs}>
                  <span>NOUS FAIRE PARVENIR</span>
                  <span className="hover-shape1"></span>
                  <span className="hover-shape2"></span>
                  <span className="hover-shape3"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Communtiy;
