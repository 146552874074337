import about_img from '../../../website/images/about_img.png'
import steps from '../../../website/images/steps.png'
function Token(){
    return(
        <>
          <section className="about" id="about">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <h1 className="section-heading">NOTRE JETON <span><img src={steps} alt="Steps" className="steps" /></span></h1>
                    <p className="about-para">NimbaCoin offre la fonctionnalité unique de compatibilité Ethereum Virtual Machine (EVM) avec un temps de blocage de 3 secondes et le service d'interopérabilité inter-chaînes. Les jetons NimbaCoin peuvent être échangés contre n'importe quelle autre crypto-monnaie, offrant des transferts et des transactions sûrs, rapides et fiables aux personnes qui cherchent à utiliser la plate-forme vidéo NimbaTube dans le monde entier. Nos recherches nous ont permis de calculer les risques et les problèmes associés à notre activité et nous visons à les réduire. Soyez le journaliste que vous avez toujours voulu être. Envoyez-nous des vidéos intéressantes et soyez récompensé avec NimbaCoin.</p>
                    <a href='https://nimbacoin.com//french-whitepaper.pdf' rel='noreferrer noopener' target='_blank' className="btn-coummunity">PAPIER BLANC </a>
                </div>
                <div className="col-lg-6 text-end" id="pot">
                    <img src={about_img} alt="Token" className="about_img" />
                </div>
            </div>
        </div>

    </section>
        </>
    )
}
export default Token