import about_img from "../../../website/images/about_img.png";
import steps from "../../../website/images/steps.png";
function Token() {
  return (
    <>
      <section className="about" id="token">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <h1 className="section-heading">
                Our Token
                <span>
                  <img src={steps} alt="Steps" className="steps" />
                </span>
              </h1>
              <p className="about-para">
                NimbaCoin offers the unique feature of Ethereum Virtual Machine
                (EVM) compatibility with a 3-second block time and the
                Cross-Chain Interoperability Service. NimbaCoin tokens can be
                exchanged for any other cryptocurrency, providing safe, swift,
                and reliable transfers and transactions to people who are
                looking to use the NimbaTube video platform worldwide. Our
                research has made sure that we calculate the risks and issues
                associated with our business and we are aiming to cut them down.
                Be the reporter you have always wanted to be. Send us
                interesting videos and get rewarded with NimbaCoin.
              </p>
              <a
                href="https://nimbacoin.com//whitepaper.pdf"
                rel="noreferrer noopener"
                target="_blank"
                className="btn-coummunity"
                type="submit"
              >
                WhitePaper
              </a>
            </div>
            <div className="col-lg-6 text-end" id="pot">
              <img src={about_img} alt="Token" className="about_img" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Token;
