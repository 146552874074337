import React, { useRef, useState } from "react";
import "./ContactFormUser.css";
const ContactFormUser = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);
  const [isMessage, setIsMessage] = useState(false);
  const [theMessage, setTheMessage] = useState("");
  const inputRef = useRef(null);
  const textRef = useRef(null);

  const [error, setError] = useState(false);
  const [sent, setSent] = useState(false);
  function handleTextareaChangeEmail(event) {
    setEmail(event.target.value);
  }
  const handleTextareaChangeMessage = (event) => {
    setMessage(event.target.value);
  };
  const handelSubmit = async (e) => {
    e.preventDefault();
    if (!sending) {
      setSending(true);
      const response = await fetch(
        "https://nimbacoin-backend.vercel.app/send-message",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },

          body: JSON.stringify({ message: message, email: email }),
        }
      );
      const data = await response.json();
      if (data.sent && !data.error) {
        inputRef.current.value = "";
        textRef.current.value = "";
        setSending(false);
        setIsMessage(true);
        setTheMessage(
          "Your message was sent, we will get out team will get you back soon."
        );
      } else {
        setSending(false);
        setTheMessage("Something went wrong while sending this message");
        setIsMessage(true);
      }
    }
  };
  return (
    <div className="container_contact_form_user">
      {isMessage && (
        <div className={"container_fixed"}>
          <p className={"container_text"}>{theMessage}</p>
          <button
            onClick={() => {
              setIsMessage(false);
              setTheMessage("");
            }}
            className={"container_close"}
          >
            close
          </button>
        </div>
      )}
      <form onSubmit={handelSubmit} className="form_main">
        <label className="email_lable">
          <span className="email_text"> email</span>
          <input
            ref={inputRef}
            onChange={handleTextareaChangeEmail}
            className="input_for_user"
            name="email"
            id="email"
            placeholder="enter your email"
          />
        </label>
        <div className="textarea_container">
          <span className="email_text"> your message </span>
          <textarea
            ref={textRef}
            onChange={handleTextareaChangeMessage}
            className="textarea"
            placeholder="enter your text"
          ></textarea>
        </div>
        <button className="button_send_nimba"> send </button>
      </form>
    </div>
  );
};

export default ContactFormUser;
