import { Link } from "react-router-dom"

function FrenchThanks(){
    return(
        <>
         <section className="secound-feature he-100" id='feature'>
        <div className="account-bg"></div>
        <div className="container feature-index">
            <div className="row align-items-center">
                <div className="col-lg-8 m-auto text-center">
                    <h1 className="thanks-logo">NIMBACOIN</h1>
                    <h1 className="thanks-heading">MERCI</h1>
                    <p className="about-para">Merci de nous avoir contactés, nous reviendrons bientôt.</p>
                    <Link to='/french' className="btn-coummunity">Revenir au site Web</Link>
                </div>
            </div>
        </div>
    </section>
        </>
    )
}
export default FrenchThanks