import steps from '../../../website/images/steps.png'
function Roadmap(){
    return(
        <>

        <section className="roadmap" id="route">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h1 className="section-heading">Feuille de route<span><img src={steps} alt="Steps" className="steps" /></span></h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 m-auto">
                        <div id="timeline-content">
    
    
                            <ul className="timeline">
                                <li className="event">
                                    <h3>T2 2022</h3>
                                    <p>Notre recherche et notre génération de livre blanc seront terminées</p>
                                </li>
    
                                <li className="event">
                                    <h3>T3 2022</h3>
                                    <p>Le développement de la pièce est achevé</p>
                                </li>
    
                                <li className="event">
                                    <h3>T3 2022</h3>
                                    <p>Elle sera suivie par la publication des multiples stratégies marketing</p>
                                </li>
    
                                <li className="event">
                                    <h3>T4 2022</h3>
                                    <p>Les jetons Nimbacoin peuvent être achetés en prévente et ICO</p>
                                </li>
    
                                <li className="event">
                                    <h3>T1 2023</h3>
                                    <p><a href='https://www.nimbatube.com/' target='_blank' rel='noreferrer noopener'> NimbaTube.com</a> est lancé</p>
                                </li>
    
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    )
}
export default Roadmap