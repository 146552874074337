import React, { useState } from "react";
// import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";
function Communtiy() {
    const [busd, setBusd] = useState("");
    const [telegram_id, setTelegram_id] = useState("");
    const [twitter_id, setTwitter_id] = useState("");
    const [email, setEmail] = useState("");
    const [bnb, setBnb] = useState("");
    const [retweet_link, setRetweet_link] = useState("");
    const navigate = useNavigate();
    // const [eroor, seterror] = useState("");
    async function ContactUs() {
     
        let data={ busd, telegram_id, twitter_id, email, bnb,retweet_link }
        // console.log(data);   
        let result = await fetch("https://nimbacoin.com/nimbacoin_backend/api",{
          method: "POST",
          headers: {
            "Content-Type":"application/x-www-form-urlencoded",
            "Accept":"application/json",
            "Access-Control-Allow-Origin":"true"
          },
          body:JSON.stringify(data),
        }) 
        if (result.status === 200) {
    
          result = await result.json();
          console.log(result.success)
          navigate('/thanks')
          setTelegram_id("");
          setTwitter_id("");
          setEmail("");
          setBnb("");
          setRetweet_link("");
        }
    
        else {
          result = await result.json();
             }
            
      }
      
  return (
    <>
      <section className="apply-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 m-auto">
              <p>NimbaCoin Whitelist will open on Sunday,  january 01, 2023 at 00:00 AM CET and close at the same time on March 31, 2023.</p>
              <ul>
                <li>
                  *   Submit your Whitelist Presale application by filling this WHITELIST FORM.
                </li>
                <li>
                  *   The whitelist registration will last until the Presale officially ends. There are no countries restrictions or KYC requirements to participate in the Presale.
                </li>
                <li>
                  *   Selected Whitelist participants will be announced on telegram channel and emailed with further instructions.
                </li>
                <li>* A spreadsheet will be published with the list of the selected participants.</li>
              </ul>
              <h2 className="mt-5">Whitelist Lottery details:</h2>
              <p>All participant to the presale are eligible to participate in the Whitelist lottery. We are aiming to create a fair opportunity to take part in the NimbaCoin/<a href='https://www.nimbatube.com/' rel='noreferrer noopener' target='_blank'> NimbaTube.com</a> project and have given a specific set of requirements that must be met in order to qualify for the Whitelist Lottery.</p>
             
              <p>In order to qualify for the Whitelist lottery, participants must:</p>
              <ul>
                <li>* 1.Fill in the whitelist form to buy at least 1000 NIMBACOINS</li>
                <li>
                * 2. Follow us on Twitter:
                  <a href="https://twitter.com/nimbacoin" rel='noreferrer noopener' target='_blank'>https://twitter.com/nimbacoin</a>
                </li>
                <li>* 3. Retweet our pinned Presale announcement on Twitter. Also quote the hashtag #NimbaCoin and explain why you are excited to take part in the sale and tag two other friends in your retweet  </li>
                <li>
                * 4. Join the NimbaCoin's Telegram community: <a href="https://t.me/officialnimbacoin" rel='noreferrer noopener' target='_blank' >https://t.me/officialnimbacoin</a> <br /> 
                </li>
              </ul>
              <p>All lists will be checked in the form of BEP-20 addresses, Telegram Handles, and Twitter Accounts. Any entry that hasn't completed all of the steps above by the end of the round will be disqualified. We will randomly select the final Presale participants from these whitelist entries and announce the lottery winners soon after.</p>
              <h2 className="mt-5">Join our community:</h2>
              <ul>
                <li>
                * Website :
                  <a href="https://nimbacoin.com/" target='_blank' rel='noreferrer noopener'>https://nimbacoin.com/</a>{" "}
                </li>
                <li>
                * Telegram Chanel:<a href="https://t.me/officialnimbacoin" target='_blank' rel='noreferrer noopener'>https://t.me/officialnimbacoin</a>{" "}
                </li>
                <li>
                * Telegram Chat  Group:<a href="https://t.me/nimbaddict" target='_blank' rel='noreferrer noopener'>https://t.me/nimbaddict</a>{" "}
                </li>
                <li>
                * Twitter :
                  <a href="https://twitter.com/nimbacoin/" target='_blank' rel='noreferrer noopener'>
                    {" "}
                    https://twitter.com/nimbacoin/
                  </a>
                </li>
                <li>
                * Discord :<a href="https://discord.gg/xmTaU8A9/" target='_blank' rel='noreferrer noopener'>https://discord.gg/xmTaU8A9/</a>
                </li>
                <li>
                * Platform :
                  <a href="http://www.nimbatube.com/" target='_blank' rel='noreferrer noopener'>
                    {" "}
                    http://www.nimbatube.com/
                  </a>
                </li>
                <li>
                * Email :
                  <a href="mailto:contact@nimbacoin.com" target='_blank' rel='noreferrer noopener'>
                    {" "}
                   contact@nimbacoin.com
                  </a>
                </li>
              </ul>
              <div className="form-section">
                <div className="input__group input__group--v2 mb-23">
                  <label>Email *</label>
                  <input
                    type="text"
                    name="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    placeholder="your email"
                    required=""
                  />
                
                </div>
                <div className="input__group input__group--v2 mb-23">
                  <label>Twitter ID : *</label>
                  <input
                    type="text"
                    name="twitter_id"
                    value={twitter_id}
                    onChange={(e) => {
                      setTwitter_id(e.target.value);
                    }}
                    placeholder="your answer"
                    required=""
                  />
                 
                </div>
                <div className="input__group input__group--v2 mb-23">
                  <label>
                    Your BNB BEP-20 address to participate in the Private Sale?
                    *Note: Please do not use smart contract or Exchange address.
                  </label>
                  <input
                    type="text"
                    name="bnb"
                    value={bnb}
                    onChange={(e) => {
                      setBnb(e.target.value);
                    }}
                    placeholder="your answer"
                    required=""
                  />
                 
                </div>
                <div className="input__group input__group--v2 mb-23">
                  <label>Telegram ID *</label>
                  <input
                    type="text"
                    name="telegram_id"
                    value={telegram_id}
                    onChange={(e) => {
                      setTelegram_id(e.target.value);
                    }}
                    placeholder="your answer"
                    required=""
                  />
                 
                </div>
                <div className="input__group input__group--v2 mb-23">
                  <label>How much do you want to invest (BUSD )? *</label>
                  <input
                    type="text"
                    name="busd"
                    value={busd}
                    onChange={(e) => {
                      setBusd(e.target.value);
                    }}
                    placeholder="your answer"
                    required=""
                  />
                 
                </div>
                <div className="input__group input__group--v2 mb-23">
                  <label>
                    Please enter your twitter retweet link of our pinned Private
                    Sale announcement? *
                  </label>
                  <input
                    type="text"
                    name="retweet_link"
                    value={retweet_link}
                    onChange={(e) => {
                      setRetweet_link(e.target.value);
                    }}
                    placeholder="your answer"
                    required=""
                  />
                 
                </div>
                <button className="theme-btn blue w-100" onClick={ContactUs}>
                  <span>Submit</span>
                  <span className="hover-shape1"></span>
                  <span className="hover-shape2"></span>
                  <span className="hover-shape3"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Communtiy;
