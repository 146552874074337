import "../../../website/css/style.css";
import Buy from "./Buy";
import Features from "./Features";
import Footer from "./Footer";
import Header from "./Header"
import Roadmap from "./Roadmap";
import Token from "./Token";
import Usps from "./Usps";
import VideoContent from "./VideoContent";

function FrenchMainPage(){
    return(
        <>
       <Header />
       <Buy />
       <Features />
       <Usps />
       <Token />
       <VideoContent />
       <Roadmap />
       <Footer />
        </>
    )
}
export default FrenchMainPage