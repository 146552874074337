import "../../../website/css/style.css";
import Footer from "../IndexPage/Footer";
import Breadcumb from "./breadcumb";
import Communtiy from "./Commuity";
import Header from "./Header";

function Form(){
    return(
        <>
        <Header />
        <Breadcumb />
        <Communtiy />
        <Footer />
        </>
    )
}
export default Form