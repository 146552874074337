import steps from '../../../website/images/steps.png'
import first from '../../../website/images/1.png'
import secound from '../../../website/images/2.png'
import third from '../../../website/images/3.png'
import fourth from '../../../website/images/4.png'
import fifth from '../../../website/images/5.png'
import sixth from '../../../website/images/6.png'
import seventh from '../../../website/images/7.png'
function Usps(){
    return(
        <>
         <section class="features">
         <div className="container usps" id="usps">
            <div className="row">
                <div className="col-lg-12">
                    <h1 className="section-heading">NOTRE USPS <span><img src={steps} alt="Steps" className="steps" /></span></h1>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-4">
                    <div className="participate-item d-flex wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.4s">
                        <div className="number-image">
                            <img src={first} alt='Nimbacoin' />
                        </div>
                        <div className="participate-info">
                            <h4 className="mb-10">UN JETON COMPLET</h4>
                            <p className="description">Nous proposons <a href='https://www.nimbatube.com/' target='_blank' rel='noreferrer noopener'> NimbaTube.com</a> et NimbaCoin sur la même plateforme. Les utilisateurs peuvent créer des comptes sur notre plateforme <a href='https://www.nimbatube.com/' target='_blank' rel='noreferrer noopener'> NimbaTube.com</a> et publier leurs vidéos ou diffuser en direct et nous les récompenserons avec les jetons qu'ils pourrons facilement vendre sur le marché de la cryptomonnaie ou conserver dans leur portefeuille.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="participate-item d-flex wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.4s" >
                        <div className="number-image">
                            <img src={secound} alt='Nimbacoin' />
                        </div>
                        <div className="participate-info">
                            <h4 className="mb-10">UN COMPTE POUR OBTENIR DES NIMBACOINS</h4>
                            <p className="description">Vous pouvez simplement commencer à gagner des NimbaCoin en vous inscrivant  sur l <a href='https://www.nimbatube.com/' target='_blank' rel='noreferrer noopener'> NimbaTube.com</a> dans le but de diffuser des contenus vidéo que vous produisez vous-même. Vous pouvez bénéficier d'une récompense d'inscription de 10 $ qui vous servira sous forme de NimbaCoin.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="participate-item d-flex wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.4s" >
                        <div className="number-image">
                            <img src={third} alt='Nimbacoin' />
                        </div>
                        <div className="participate-info">
                            <h4 className="mb-10">UN SYSTÈME INTÉGRÉ POUR LES PAIEMENTS</h4>
                            <p className="description">Notre objectif principal est de rendre les paiements et les transactions sur <a href='https://www.nimbatube.com/' target='_blank' rel='noreferrer noopener'> NimbaTube.com</a> aussi sûrs et efficaces que possible. Les pièces seront facilement transférées vers vos portefeuilles électroniques pour une utilisation efficace. Vous pouvez en outre échanger vos jetons contre d'autres cryptomonnaies  telles que Bitcoin, Ethereum ou Binance (BNB) pour obtenir un maximum de profits.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4">
                    <div className="participate-item d-flex wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.4s" >
                        <div className="number-image">
                            <img src={fourth} alt='Nimbacoin' />
                        </div>
                        <div className="participate-info">
                            <h4 className="mb-10">AMÉLIORATION DE LA FIDÉLISATION DES CLIENTS</h4>
                            <p className="description">Les abonnés sur la page Web NimbaCoin et <a href='https://www.nimbatube.com/' target='_blank' rel='noreferrer noopener'> NimbaTube.com</a> bénéficient de services personnalisés. L'utilisation de l’intelligence artificielle et d'outils d'analyse nous permet de suivre leurs besoins et d'améliorer nos services. Valoriser nos clients a été un excellent moyen de les fidéliser.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="participate-item d-flex wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.4s">
                        <div className="number-image">
                            <img src={fifth} alt='Nimbacoin' />
                        </div>
                        <div className="participate-info">
                            <h4 className="mb-10">AMÉLIORATION DE LA PART DES CLIENTS DANS LE PORTEFEUILLE</h4>
                            <p className="description">Nous avons augmenté la part de portefeuille de nos clients en les récompensant avec des jetons Nimbacoin lorsqu'ils s'inscrivent avec nous et nous fournissent leurs vidéos à diffuser sur <a href='https://www.nimbatube.com/' target='_blank' rel='noreferrer noopener'> NimbaTube.com</a></p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="participate-item d-flex wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.4s">
                        <div className="number-image">
                            <img src={sixth} alt='Nimbacoin' />
                        </div>
                        <div className="participate-info">
                            <h4 className="mb-10">SENSIBILISATION ACCRUE À LA MARQUE</h4>
                            <p className="description">
                            Notre objectif est de nous assurer que nous commercialisons les utilisateurs et leurs produits de la meilleure façon. Nous attribuerons des pièces à nos utilisateurs qui s'inscrivent sur <a href='https://www.nimbatube.com/' target='_blank' rel='noreferrer noopener'> NimbaTube.com</a> et téléchargent du contenu vidéo. Nous voulons agir comme un pont entre les producteurs de contenus vidéo et les clients pour s'assurer que les deux parties bénéficient l'une de l'autre tout en atteignant leurs objectifs.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-4">
                    <div className="participate-item d-flex wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.4s">
                        <div className="number-image">
                            <img src={seventh} alt='Nimbacoin' />
                        </div>
                        <div className="participate-info">
                            <h4 className="mb-10">CONFIANCE & SÉCURITÉ</h4>
                            <p className="description">NimbaCoin sera basé sur Binance smart Chain, une plate-forme décentralisée assurant une sécurité maximale pour le jeton au cours des transactions. Pendant ce temps, <a href='https://www.nimbatube.com/' target='_blank' rel='noreferrer noopener'> NimbaTube.com</a> vérifiera les utilisateurs qui s'inscriront et enverront des vidéos avant d'autoriser toute transaction. Ceci est fait principalement pour résoudre les problèmes de fraude et d'escroqueries.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </section>
        </>
    )
}
export default Usps