import React, { useEffect, useState } from "react";
function Dashboards() {
  
  const [data, setData] = useState([])
  const [busd, setBusd] = useState("");
    const [telegram_id, setTelegram_id] = useState("");
    const [twitter_id, setTwitter_id] = useState("");
    const [email, setEmail] = useState("");
    const [bnb, setBnb] = useState("");
    const [retweet_link, setRetweet_link] = useState("");
    const [userId, setUserId] = useState(null)  
    useEffect(() => {
      getList();
    }, [])
    function getList() {
      fetch("https://nimbacoin.com/nimbacoin_backend/api/submitUsers").then((result) => {
        result.json().then((resp) => {
          // console.warn(resp)
          setData(resp.users)
          setBusd(resp[0].busd)
          console.log(resp[0].telegram_id)
          setTwitter_id(resp[0].twitter_id)
          setEmail(resp[0].email)
          setBnb(resp[0].bnb)
          setRetweet_link(resp[0].retweet_link)
          setUserId(resp[0].id)
        })
      })
    }
  return (
    <>
        <div className="content-body">
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Users</h4>
            </div>
            <div className="card-body">
              <div className="table-responsive">
              <table id="myTable" class="display">
        <thead>
            <tr>
                <th>Id</th>
                <th>Telegram</th>
                <th>Twitter</th>
                <th>Email</th>
                <th>BNB</th>
                <th>Busd</th>
                <th>Retweet</th>
            </tr>
        </thead>
        {

data.map((item, i) =>

  <tbody>
    <tr key={item.id}>
      <td>{ i+1}</td>
      <td>{item.telegram_id}</td>
      <td>{item.twitter_id}</td>
      <td>{item.email}</td>
      <td>{item.bnb}</td>
      <td>{item.busd}</td>
      <td>{item.retweet_link}</td>
    </tr>
  </tbody>
)

}
      
    </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </>
  );
}
export default Dashboards;
