
import marketing from '../../../website/images/marketing.png'
import marketing1 from '../../../website/images/marketing1.png'
function VideoContent(){
    return(
        <>
        <section className="crypto">
        <div className="container">
            <div className="row align-items-center mt-5 mb-5">
                <div className="col-lg-6">
                    <h3 className="inner-headiing">Notre strategie de marketing</h3>
                    <p className="about-para">NimbaCoin et <a href='https://www.nimbatube.com/' target='_blank' rel='noreferrer noopener'> NimbaTube.com</a> viseront à atteindre autant d'exposition que possible. Pour cette raison, des outils de marketing tels que la publicité via les distributeurs, le marketing des réseaux  sociaux, le marketing d'affiliation et les promotions payantes seront utilisés pour atteindre les utilisateurs dans les écosystèmes des  contenus vidéo et de la blockchain.</p>
                </div>
                <div className="col-lg-6 align-custom animated-img">
                    <img src={marketing} className="market-img" alt='NimbaCoin' />
                </div>
            </div>
            <div className="row align-items-center mt-5">
                <div className="col-lg-6 align-customs animated-img">
                    <img src={marketing1} className="market-img" alt='NimbaCoin' />
                </div>
                <div className="col-lg-6 mbt-5">
                    <h3 className="inner-headiing">Marketing direct</h3>
                    <p className="about-para">Nous sommes une entreprise de médias qui a pour mission d’apporter de l’innovation dans le milieu centralisé du journalisme : En lieu et place  d'avoir des correspondants dans le monde entier, nous récompenserons avec Nimbacoin pour diffuser en direct du contenu ou télécharger des vidéos d'événements (reportages) sur la plate-forme <a href='https://www.nimbatube.com/' target='_blank' rel='noreferrer noopener'> NimbaTube.com</a>. Cette innovation permettra  d’améliorer les conditions de travail de reporters dans des endroits a risque comme  l'Afrique. Nous ciblerons les magazines commerciaux et des entreprises de meme  que des sites Web pour diffuser leurs vidéos sur notre plate-forme . Le marketing direct dispose d'un large éventail d'outils pouvant être utilisés, tels que la presse écrite ou les médias sociaux.</p>
                </div>

            </div>
        </div>
    </section>
        </>
    )
}
export default VideoContent