import how from '../../../website/images/how.png'
import earth from '../../../website/images/earth.png'
import { Link } from 'react-router-dom'
function Buy(){
    return(
        <>
         <section className="features" id='acheter'>
        <div className="container">
            <div className="row"></div>
            <div className="feature-card">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="mobile_bg">
                            <img src={how} className="how" alt='Nimbacoin' />
                            <div className="rotate">
                                <img src={earth} className="rotateting" alt='Nimbacoin' />
                            </div>

                            <span className="border-shadow shadow-1"></span>
                            <span className="border-shadow shadow-2"></span>
                            <span className="border-shadow shadow-3"></span>
                            <span className="border-shadow shadow-4"></span>
                        </div>
                    </div>
                    <div className="col-lg-6">

                        <div className="feature-header">
                            <h1>COMMENT ACHETER DES NIMBACOINS ?</h1>
                            <p>Les pré-ICOs permettent aux crypto-investisseurs d'acheter des jetons d’un projet crypto à un prix réduit avant la vente publique officielle. Soyez le premier à rejoindre le projet <a href='https://www.nimbatube.com/' target='_blank' rel='noreferrer noopener' > NimbaTube.com</a> NimbaCoin et profitez-en au maximum.</p>
                            <p>Achetez des jetons à un prix réduit soit en remplissant le formulaire de prévente, soit en échangeant vos crypto-monnaies contre des jetons NimbaCoin sur la plateforme <a href='https://pancakeswap.finance/swap?outputCurrency=0x2f8A45dE29bbfBB0EE802B340B4f91af492C6DE7&inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56' target='_blank' rel='noreferrer noopener' >Pancakeswap.</a></p>
                        </div>
                      
                        <div className="button-flex text-center">
                  <Link to='/frenchform' className="btn-coummunity mr-2">
                  REJOIGNEZ LA PRÉVENTE
                  </Link>
                  <a href='https://pancakeswap.finance/swap?outputCurrency=0x2f8A45dE29bbfBB0EE802B340B4f91af492C6DE7&inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56' target='_blank' className="btn-custom  ml-2 mt-20" rel='noreferrer noopener'>
                  BUSD/NIMBA
                  </a>
                </div>
                    </div>
                </div>
            </div>
        </div>
       
    </section>
        </>
    )
}
export default Buy