function Breadcumb() {
  return (
    <>
      <section className="breadcumb">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h1 className="form-heading">
              La  vente  PRIVÉE de NimbaCoin est maintenant disponible!
              </h1>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Breadcumb;
