import marketing from "../../../website/images/marketing.png";
import marketing1 from "../../../website/images/marketing1.png";
function VideoContent() {
  return (
    <>
      <section className="crypto">
        <div className="container">
          <div className="row align-items-center mt-5 mb-5">
            <div className="col-lg-6">
              <h3 className="inner-headiing">Our marketing strategy</h3>
              <p className="about-para">
                NimbaCoin and NimbaTube.com will be aiming to attain as much
                exposure as possible. For this reason, marketing tools such as
                advertising through distributors, social media marketing,
                affiliate marketing, and paid promotions will be used to reach
                out to users in the video content and blockchain ecosystems.
              </p>
            </div>
            <div className="col-lg-6 align-custom animated-img">
              <img src={marketing} className="market-img" alt="NimbaCoin" />
            </div>
          </div>
          <div className="row align-items-center mt-5">
            <div className="col-lg-6 align-customs animated-img">
              <img src={marketing1} className="market-img" alt="NimbaCoin" />
            </div>
            <div className="col-lg-6 mbt-5">
              <h3 className="inner-headiing">Direct marketing</h3>
              <p className="about-para">
                We are a media company on a mission to disrupt the centralized
                reporter job: Instead of having correspondents all over the
                world we are going to pay people with Nimbacoin to live stream
                or upload videos of events (reports) on the NimbaTube.com
                platform. It is safe in places, unlike Africa where people are
                at risk for reporting things happening.
              </p>
              <p className="about-para">
                We will be targeting business and corporate magazines and
                websites to stream their videos on our platform and earn our
                coins. Direct marketing has a wide range of tools that can be
                utilized such as print media or social media.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default VideoContent;
