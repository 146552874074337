import { NavLink } from "react-router-dom";

function Header() {
  return (
    <>
      <section className="default-header" id="nimbaform">
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <NavLink className="navbar-brand" to="/">
              NimbaCoin
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <div className="bar">
                <span className="dot1"></span>
                <span className="dot2"></span>
                <span className="dot3"></span>
              </div>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav m-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink className="nav-link" to="/">
                    NimbaCoin Private Sale whitelist is Live Now!
                  </NavLink>
                </li>
              </ul>
              <div className="d-flex">
                <NavLink to="/frenchform" className="btn-custom">
                  FRANÇAIS
                </NavLink>
              </div>
            </div>
          </div>
        </nav>
      </section>
    </>
  );
}
export default Header;
