import how from "../../../website/images/how.png";
import earth from "../../../website/images/earth.png";
import "./buy.css";
// import { Link } from 'react-router-dom'
function Buy() {
  return (
    <>
      <section className="features" id="about">
        <div className="container">
          <div className="row"></div>
          <div className="feature-card">
            <div className="row">
              <div className="col-lg-6">
                <div className="mobile_bg">
                  <img src={how} className="how" alt="Nimbacoin" />
                  <div className="rotate">
                    <img src={earth} className="rotateting" alt="Nimbacoin" />
                  </div>

                  <span className="border-shadow shadow-1"></span>
                  <span className="border-shadow shadow-2"></span>
                  <span className="border-shadow shadow-3"></span>
                  <span className="border-shadow shadow-4"></span>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="feature-header">
                  <h1> HOW TO BUY NIMBACOIN ?</h1>
                  <p>
                    Pre-ICOs let crypto investors buy the necessary tokens at a
                    discounted price before the official public sale. Be the
                    first to join
                    <a
                      href="https://www.nimbatube.com/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {" "}
                      NimbaTube.com/
                    </a>{" "}
                    NimbaCoin project and make the most out of it.
                  </p>
                  <p>
                    Buy tokens at a discounted price by following the link
                    below.
                  </p>
                </div>

                <div className="join-pre-ico">
                  <a
                    href="https://www.nimbatube.com/swap/index"
                    className="link_button"
                    target="_blank"
                  >
                    Join pre-ico
                  </a>
                  {/* <a
                    href="https://pancakeswap.finance/swap?outputCurrency=0x2f8A45dE29bbfBB0EE802B340B4f91af492C6DE7&inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
                    target="_blank"
                    className="btn-custom  ml-2 mt-20"
                    rel="noreferrer noopener"
                  >
                    BUSD/NIMBA
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Buy;
