import { Link } from "react-router-dom"

function Thanks(){
    return(
        <>
         <section className="secound-feature he-100" id='feature'>
        <div className="account-bg"></div>
        <div className="container feature-index">
            <div className="row align-items-center">
                <div className="col-lg-8 m-auto text-center">
                    <h1 className="thanks-logo">NIMBACOIN</h1>
                    <h1 className="thanks-heading">THANK YOU</h1>
                    <p className="about-para">Thanks for contacting us, we will revert back soon.</p>
                    <Link to='/' className="btn-coummunity">Go Back to Website</Link>
                </div>
            </div>
        </div>
    </section>
        </>
    )
}
export default Thanks