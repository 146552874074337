function Footer(){
    return(

        <>
        
        <footer className="footer">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 m-auto">
                <ul className="footer-icon-list">
                <li>
                  <a href="https://t.me/nimbatube" target="_blank">
                  <i className="fa-brands fa-telegram"></i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/nimbatube" target="_blank">
                    <i className="fa-brands fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="/">
                  <i className="fa-brands fa-discord"></i>
                  </a>
                </li>
              </ul>

                    <div className="footer-logo text-center">
                        <h1>NimbaCoin</h1>
                    </div>

                    <div className="footer-mainmenu text-center mb-20">
                        <ul>
                            <li>
                                <a href="#bienvenue">Bienvenue sur Nimbacoin</a>
                            </li>
                            <li>
                                <a href="#acheter">Comment acheter des Nimbacoins?</a>
                            </li>
                            <li>
                                <a href="#caractéristiques">Caractéristiques de NimbaCoin</a>
                            </li>
                            <li>
                                <a href="#usps">Notre USPS</a>
                            </li>
                            <li>
                                <a href="#route">Feuille de route</a>
                            </li>
                        </ul>
                    </div>
                    <p className="copyright">Copyright © 2022. Tous droits réservés par NimbaCoin</p>
                </div>
            </div>
        </div>
    </footer>
</>
    )
}
export default Footer