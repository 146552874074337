import steps from "../../../website/images/steps.png";
function Roadmap() {
  return (
    <>
      <section className="roadmap" id="roadmap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h1 className="section-heading">
                Roadmap{" "}
                <span>
                  <img src={steps} alt="Steps" className="steps" />
                </span>
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 m-auto">
              <div id="timeline-content">
                <ul className="timeline">
                  <li className="event">
                    <h3>Q2 2022</h3>
                    <p>
                      Our Research and White Paper generation are completed{" "}
                    </p>
                  </li>

                  <li className="event">
                    <h3>Q2 2022</h3>
                    <p>The coin development is released</p>
                  </li>

                  <li className="event">
                    <h3>Q3 2022</h3>
                    {/* <p>It will be followed by the release of the multiple marketing strategies</p> */}
                    <p> Development of the nimbatube.com platform start</p>
                  </li>

                  <li className="event">
                    <h3>Q3 2023</h3>
                    <p>
                      <a
                        href="https://www.nimbatube.com/"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        {" "}
                        NimbaTube.com
                      </a>{" "}
                      is launched
                    </p>
                  </li>
                  <li className="event">
                    <h3>Q4 2023</h3>
                    <p>Nimbacoin tokens can be purchased on pre-ico</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Roadmap;
