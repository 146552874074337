import { Routes, Route  } from 'react-router-dom'
import Dashboard from './admin/Pages/Dashboard';
import Login from './admin/Pages/Login';
import FrenchForm from './site/Components/FrenchNimbaForm/FrenchForm';
import FrenchThanks from './site/Components/FrenchNimbaForm/FrenchThanks';
import FrenchMainPage from './site/Components/FrenchPage/FrenchMainPage';
import IndexMainPage from './site/Components/IndexPage/IndexMainPage';
import Form from './site/Components/NimbaForm/Form';
import Thanks from './site/Components/NimbaForm/Thanks';

function App() {
  return (
   <>
   <Routes>
    <Route exact path="/" element={<IndexMainPage/>}  />
    <Route exact path="/french" element={<FrenchMainPage/>}  />
    <Route exact path="/form" element={<Form/>}  />
    <Route exact path="/login" element={<Login/>}  />
    <Route exact path="/dashboard" element={<Dashboard/>}  />
    <Route exact path="/frenchform" element={<FrenchForm/>}  />
    <Route exact path="/thanks" element={<Thanks/>}  />
    <Route exact path="/fthanks" element={<FrenchThanks/>}  />
   </Routes>
   </>
  );
}

export default App;
