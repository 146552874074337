function Footer(){
    return(
        <>    
        <div className="footers">
        <div className="copyrights text-center">
            <p>©2022 Nimbacoin All rights reserved.</p>
        </div>
    </div>
    </>
    )
}
export default Footer