import "../../../website/css/style.css";
import Buy from "./Buy";
import ContactFormUser from "./ContactFormUser";
import Feature from "./Feature";
import Footer from "./Footer";
import Header from "./Header";
import Roadmap from "./Roadmap";
import Token from "./Token";
import Usps from "./Usps";
import VideoContent from "./VideoContent";
function IndexMainPage() {
  return (
    <>
      <Header />
      <Buy />
      <Feature />
      <Usps />
      <Token />
      <VideoContent />
      <Roadmap />
      <ContactFormUser />
      <Footer />
    </>
  );
}
export default IndexMainPage;
