import steps from '../../../website/images/steps.png'
import about from '../../../website/images/about-img.png'
function Feature(){
    return(
        <>
         <section className="secound-feature" id='caractéristiques'>
        <div className="account-bg"></div>
        <div className="container feature-index">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <h1 className="section-heading">CARACTÉRISTIQUES DE NIMBACOIN<span><img src={steps} alt="Steps" className="steps" /></span></h1>
                    <p className="about-para">NimbaCoin est un jeton BEP 20 qui fonctionne en utilisant le consensus distribué sur la chaine intelligente Binance. Il est échangeable contre toute autre crypto-monnaie comme Ethereum, Bitcoin ou Binance (BNB).</p>
                    <p className="about-para">Voici quelques détails utiles sur le token :</p>
                    <div className="feature-body">
                            <p>-L’algorithme de hachage est BEP20</p>
                            <p>-Le temps moyen pour valider l'existence d'un nouveau lot de jetons NimbaCoin est de 3 secondes</p>
                            <p>-L’approvisionnement maximum est de 500 000 000 (500 M) de jetons</p>
                            <p>-Nous proposons un transfert inter-chaînes vers Binance Smart Chain ainsi que des échanges contre d'autres crypto-monnaies</p>
                            <p>-Le prix pré-ICO de NimbaCoin est de 1 cent par jeton</p>
                            <p>-L’ICO coûtera 10 cents par jeton</p>
                            <p>-NimbaCoin ne peut être utilisé que directement depuis<a href='https://www.nimbatube.com/' rel='noreferrer noopener' target='_blank'> NimbaTube.com</a></p>
                        </div>
                    <a href='https://nimbacoin.com//french-whitepaper.pdf' rel='noreferrer noopener' target='_blank' className="btn-coummunity">PAPIER BLANC </a>
                </div>
                <div className="col-lg-6 text-end" id="pot">
                    <img src={about} alt="Token" className="about_img" />
                </div>
            </div>
        </div>
    </section>
        </>
    )
}
export default Feature