
import steps from '../../../website/images/steps.png'
import about from '../../../website/images/about-img.png'
function Feature(){
    return(
        <>
         <section className="secound-feature" id='feature'>
        <div className="account-bg"></div>
        <div className="container feature-index">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <h1 className="section-heading">FEATURES OF NIMBACOIN<span><img src={steps} alt="Steps" className="steps" /></span></h1>
                    <p className="about-para">NimbaCoin is a BEP 20 token which work using the distributed consensus on the Binance Smart Chain. It is exchangeable for any other cryptocurrency such as Ethereum, Bitcoin or Binance coin (BNB).</p>
                    <p className="about-para">Below are some useful details about the token :</p>
                    <div className="feature-body">
                            <p>-Hashing Algorithm is BEP20</p>
                            <p>-The average time to validate the existence of a new batch of NimbaCoin tokens is 3 seconds</p>
                            <p>-Maximum supply is 500,000,000 (500 M) tokens</p>
                            <p>-We offer cross-chain transfer to Binance Smart Chain as well as trading against other cryptocurrencies</p>
                            <p>-NimbaCoin's pre-ICO price is 1 cent per token</p>
                            {/* <p>-ICO will price at 10 cents per token</p> */}
                            <p>-NimbaCoin can only be used directly from<a href='https://www.nimbatube.com/' rel='noreferrer noopener' target='_blank'> NimbaTube.com</a></p>
                        </div>
                    <a href='https://nimbacoin.com//whitepaper.pdf' rel='noreferrer noopener' target='_blank' className="btn-coummunity">WhitePaper</a>
                </div>
                <div className="col-lg-6 text-end" id="pot">
                    <img src={about} alt="Token" className="about_img" />
                </div>
            </div>
        </div>
    </section>
        </>
    )
}
export default Feature