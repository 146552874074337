import '../../assets/css/style.css'
import Dashboards from '../Components/Dashboards'
import Header from "../layout/Header"
import { useNavigate } from "react-router-dom";
import { useEffect  } from "react";
import Footer from '../layout/Footer';
function Dashboard(){
    const navigate = useNavigate();
    useEffect(() => {
        const loggedInUser = localStorage.getItem("token");
        if (!loggedInUser) {
          navigate('/login')
        }
        else{
          navigate('/dashboard')
        }
      }, [navigate]);
    return(
        <>
        <div className='dasboard-bg'>
        <div className='dash-bg'>
        <Header />
        <Dashboards />
        </div>
        </div>
        
        <Footer />
        </>
    )
}
export default Dashboard