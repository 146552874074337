import { NavLink } from "react-router-dom";

// import { Link } from "react-router-dom";
function Header() {
  return (
    <>
      <header className="header" id="home">
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <NavLink className="navbar-brand" to="/">
              NimbaCoin
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <div className="bar">
                <span className="dot1"></span>
                <span className="dot2"></span>
                <span className="dot3"></span>
              </div>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav m-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    aria-current="page"
                    href="#home"
                  >
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#about">
                    ABOUT NIMBACOIN
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#feature">
                    FEATURES
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#usps">
                    OUR USPS
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#roadmap">
                    ROADMAP
                  </a>
                </li>
              </ul>
              <div className="d-flex">
                {/* <NavLink to="/french" className="btn-custom">
                  FRANÇAIS
                </NavLink> */}
              </div>
            </div>
          </div>
        </nav>
        <section className="banner" id="home">
          <div className="contianer">
            <div className="row">
              <div className="col-lg-7 m-auto text-center">
                <h1 className="heading">Welcome to Nimbacoin</h1>
                <p className="paragraph">
                  Pool your videos on{" "}
                  <a
                    href="https://www.nimbatube.com/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {" "}
                    NimbaTube.com
                  </a>{" "}
                  and gain Nimbacoins, the next-generation BEP 20 tokens deploy
                  on the revolutionized Binance Smart Chain Blockchain.
                </p>
                <div className="button-flex text-center justify-content-center">
                  <a
                    href="https://www.nimbatube.com/swap/index"
                    className="btn-coummunity mr-2"
                    // type="submit"
                    target="_blank"
                  >
                    join pre-ico
                  </a>
                  <a
                    href="https://bscscan.com/token/0x2f8A45dE29bbfBB0EE802B340B4f91af492C6DE7?a=0x2f8A45dE29bbfBB0EE802B340B4f91af492C6DE7"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="btn-coummunity ml-2 mt-20"
                    type="submit"
                  >
                    + CONTRACT ADRESS{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </header>
    </>
  );
}
export default Header;
