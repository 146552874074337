function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 m-auto">
              <ul className="footer-icon-list">
                <li>
                  <a href="https://t.me/nimbatube" target="_blank">
                  <i className="fa-brands fa-telegram"></i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/nimbatube" target="_blank">
                    <i className="fa-brands fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="/">
                  <i className="fa-brands fa-discord"></i>
                  </a>
                </li>
              </ul>

              <div className="footer-logo text-center">
                <h1>NimbaCoin</h1>
              </div>

              <div className="footer-mainmenu text-center mb-20">
                <ul>
                  <li>
                    <a href="#home">Home</a>
                  </li>
                  <li>
                    <a href="#about">About</a>
                  </li>
                  <li>
                    <a href="#feature">Features</a>
                  </li>
                  <li>
                    <a href="#token">Token</a>
                  </li>
                  <li>
                    <a href="#usps">USPS</a>
                  </li>
                  <li>
                    <a href="#roadmap">Roadmap</a>
                  </li>
                </ul>
              </div>
              <p className="copyright">
                Copyright © 2022. All Rights Reserved by NimbaCoin
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;
