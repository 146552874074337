import steps from "../../../website/images/steps.png";
import first from "../../../website/images/1.png";
import secound from "../../../website/images/2.png";
import third from "../../../website/images/3.png";
import fourth from "../../../website/images/4.png";
import fifth from "../../../website/images/5.png";
import sixth from "../../../website/images/6.png";
import seventh from "../../../website/images/7.png";
function Usps() {
  return (
    <>
      <section class="features">
        <div className="container usps" id="usps">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="section-heading">
                Our USPS{" "}
                <span>
                  <img src={steps} alt="Steps" className="steps" />
                </span>
              </h1>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <div
                className="participate-item d-flex wow fadeInUp"
                data-wow-delay="0.2s"
                data-wow-duration="0.4s"
              >
                <div className="number-image">
                  <img src={first} alt="Nimbacoin" />
                </div>
                <div className="participate-info">
                  <h4 className="mb-10">A COMPLETE TOKEN</h4>
                  <p className="description">
                    We offer{" "}
                    <a
                      href="https://www.nimbatube.com/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {" "}
                      NimbaTube.com
                    </a>{" "}
                    and NimbaCoin on the same platform. Users can create
                    accounts on NimbaTube.com and post videos or live stream and
                    we will reward them with Nimbacoin tokens which they can
                    easily exchange on the crypto market against
                    well-established cryptocurrencies like BNB, BTC, or ETH, or
                    simply hold in their wallets for bigger rewards.
                  </p>

                  {/* <p className="description">We offer <a href='https://www.nimbatube.com/' target='_blank' rel='noreferrer noopener' > NimbaTube.com</a> and NimbaCoin on the same platform. Users can create accounts on <a href='https://www.nimbatube.com/' target='_blank' rel='noreferrer noopener' > NimbaTube.com</a> and post videos or live stream and we will reward them with Nimbacoin tokens which they can easily exchange on the crypto market against well established crytocurrencies like BNB,BTC or ETH , or simply hold in their wallets for bigger rewards.</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="participate-item d-flex wow fadeInUp"
                data-wow-delay="0.2s"
                data-wow-duration="0.4s"
              >
                <div className="number-image">
                  <img src={secound} alt="Nimbacoin" />
                </div>
                <div className="participate-info">
                  <h4 className="mb-10">AN ACCOUNT TO GET NIMBACOINS</h4>
                  <p className="description">
                    You can simply start earning NimbaCoins by registering for
                    an account on{" "}
                    <a
                      href="https://www.nimbatube.com/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {" "}
                      NimbaTube.com
                    </a>{" "}
                    for the purpose of sharing or streaming videos you produce
                    yourself. You can avail of a signup reward of $10 which will
                    be served to you in the form of NimbaCoins.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="participate-item d-flex wow fadeInUp"
                data-wow-delay="0.2s"
                data-wow-duration="0.4s"
              >
                <div className="number-image">
                  <img src={third} alt="Nimbacoin" />
                </div>
                <div className="participate-info">
                  <h4 className="mb-10">INTEGRATED SYSTEM FOR PAYMENTS</h4>
                  <p className="description">
                    Our main aim is to make the payments and transactions on{" "}
                    <a
                      href="https://www.nimbatube.com/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {" "}
                      NimbaTube.com
                    </a>{" "}
                    as safe and efficacious as possible. The coins will be
                    easily transferred to your e-wallets for effective usage.
                    You can further exchange the coin with other coins such as
                    Bitcoin, Ethereum, or Binance coin (BNB) for gaining maximum
                    profits.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div
                className="participate-item d-flex wow fadeInUp"
                data-wow-delay="0.2s"
                data-wow-duration="0.4s"
              >
                <div className="number-image">
                  <img src={fourth} alt="Nimbacoin" />
                </div>
                <div className="participate-info">
                  <h4 className="mb-10">IMPROVED CUSTOMERS RETENTION</h4>
                  <p className="description">
                    Subscribers on the NimbaCoin webpage and NimbaTube.com get
                    customized services. Using Al and analytical tools allows us
                    to keep track of their needs and improve our services to
                    them. Valuing our customers has been a great way to gain
                    their loyalty.
                  </p>
                  {/* <p className="description">
                    Subscribers on NimbaCoin webpage and{" "}
                    <a
                      href="https://www.nimbatube.com/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {" "}
                      NimbaTube.com
                    </a>{" "}
                    get customized services. Using Al and analytical tools allow
                    us to keep track of their needs and improve our services to
                    them . Valuing our customers has been a great way to gain
                    their loyalty.
                  </p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="participate-item d-flex wow fadeInUp"
                data-wow-delay="0.2s"
                data-wow-duration="0.4s"
              >
                <div className="number-image">
                  <img src={fifth} alt="Nimbacoin" />
                </div>
                <div className="participate-info">
                  <h4 className="mb-10">ENHANCED CUSTOMERS’SHARE OF WALLET</h4>
                  <p className="description">
                    We have been increasing our customer's share of wallet by
                    providing and rewarding them with Nimbacoin tokens when they
                    sign up with us and provide us with their videos to stream
                    on NimbaTube.com
                    {/* We have been increasing our customers’share of wallet by
                    providing and rewarding them with Nimbacoin tokens when they
                    sign up with us and provide us their videos to stream on{" "}
                    <a
                      href="https://www.nimbatube.com/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {" "}
                      NimbaTube.com
                    </a> */}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="participate-item d-flex wow fadeInUp"
                data-wow-delay="0.2s"
                data-wow-duration="0.4s"
              >
                <div className="number-image">
                  <img src={sixth} alt="Nimbacoin" />
                </div>
                <div className="participate-info">
                  <h4 className="mb-10">NCREASED BRAND AWARENESS AND EQUITY</h4>
                  <p className="description">
                    We aim to ensure that we market the users and their products
                    in the best way. We will be awarding coins to our users who
                    register on{" "}
                    <a
                      href="https://www.nimbatube.com/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {" "}
                      NimbaTube.com
                    </a>{" "}
                    and upload videos contents. We want to act as a bridge
                    between the video content producers and customers to ensure
                    that both parties benefit from each other while achieving
                    their aims.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4">
              <div
                className="participate-item d-flex wow fadeInUp"
                data-wow-delay="0.2s"
                data-wow-duration="0.4s"
              >
                <div className="number-image">
                  <img src={seventh} alt="Nimbacoin" />
                </div>
                <div className="participate-info">
                  <h4 className="mb-10">TRUST & SECURITY</h4>
                  <p className="description">
                    NimbaCoin will be based on Binance smart Chain a
                    decentralized platform ensuring maximum safety and security
                    for the token being in a transaction. Meanwhile,
                    NimbaTube.com will be verifying users who will be signing up
                    and sending videos before allowing any transaction to be
                    carried out. This is done primarily to resolve issues of
                    fraud and scams.
                    {/* NimbaCoin will be based on Binance smart Chain a
                    decentralized platform ensuring maximum safety and security
                    for the token being in transaction. Meanwhile{" "}
                    <a
                      href="https://www.nimbatube.com/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {" "}
                      NimbaTube.com
                    </a>{" "}
                    will be verifying users who will be signing up and sending
                    videos before allowing any transaction being carried out.
                    This is done primarily to resolve issues of fraud and scams. */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Usps;
