import { Link, NavLink,useNavigate} from "react-router-dom";
import { useEffect  } from "react";
function openNav() {
    document.getElementById("mySidenav").style.display = "block";
} 
function closeNav() {
    document.getElementById("mySidenav").style.display = "none";
}

function Header(){
    const navigate = useNavigate();
    function Logout(){
        localStorage.clear('token')
        navigate('/login')
    }
    useEffect(() => {
        const loggedInUser = localStorage.getItem("token");
        if (!loggedInUser) {
          navigate('/login')
        }
        else{
          navigate('/header')
        }
      }, [navigate]);
    return(
        <>
        <div className="nav-header">
            <Link to='/dashboard' className="brand-logo">
                <h1>NimbaCoin</h1>
            </Link>

            
        </div>
     
        <div className="header-bg">
            <div className="header-content">
                <nav className="navbar navbar-expand">
                    <div className="collapse navbar-collapse justify-content-between">
                        <div className="header-left">
<div className="had-bars" onClick={openNav}>
<i class="fa-solid fa-bars-staggered"></i>
</div>


                            <div className="dashboard_bar">
                                dashboard
                            </div>
                        </div>

					</div>
                </nav>
            </div>
        </div>
        <div className="deznav desk_nav">
            <div className="deznav-scroll">
				<ul className="metismenu">
                    <li>
                    <div className="closebtn"></div>          
                  
                    </li>
                    <li><NavLink to="/dashboard" refresh="true" className="mt-3" aria-expanded="false">
                    <i class="fa-solid fa-globe"></i>
							<span className="nav-text">Dashboard</span>
						</NavLink>
                    </li>

                  
                    <li><a className="curusor" onClick={Logout} aria-expanded="false">
                    <i class="fa-solid fa-lock"></i>
							<span className="nav-text">Logout</span>
						</a>
                    </li>

                  
                </ul>
				
			</div>
        </div>
        <div className="deznav mbobile_nav" id="mySidenav" >
            <div className="deznav-scroll">
				<ul className="metismenu">
                    <li>
                    <div className="closebtn" onClick={closeNav}><i class="fa-solid fa-xmark"></i></div>          
                  
                    </li>
                    <li><NavLink to="/dashboard" refresh="true" className="mt-3" aria-expanded="false">
                    <i class="fa-solid fa-globe"></i>
							<span className="nav-text">Dashboard</span>
						</NavLink>
                    </li>

                  
                    <li><a className="curusor" onClick={Logout} aria-expanded="false">
                    <i class="fa-solid fa-lock"></i>
							<span className="nav-text">Logout</span>
						</a>
                    </li>

                  
                </ul>
				
			</div>
        </div>
        </>
    )
}
export default Header