import { NavLink } from "react-router-dom"
function Header(){
    return(
        <>
         <header className="header" id="bienvenue">
        <nav className="navbar navbar-expand-lg">
            <div className="container align-items-end">
                <NavLink className="navbar-brand" to="/">NimbaCoin</NavLink>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <div className="bar">
                        <span className="dot1"></span>
                        <span className="dot2"></span>
                        <span className="dot3"></span>
                    </div>
      </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav m-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="#bienvenue">ACCUEIL</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#acheter"> À PROPOS DE NIMBACOIN</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#caractéristiques">CARACTÉRISTIQUES</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#usps">NOS USPS</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#route">FEUILLE DE ROUTE</a>
                        </li>
                    </ul>
                    <div className="d-flex">
                        <NavLink to="/" className="btn-custom">ANGLAIS</NavLink>
                        </div>
                </div>
            </div>
        </nav>
        <section className="banner" id="home">
            <div className="contianer">
                <div className="row">
                    <div className="col-lg-7 m-auto text-center">
                        <h1 className="heading">Bienvenue sur Nimbacoin</h1>
                        <p className="paragraph">Regroupez vos vidéos sur <a href="https://www.nimbatube.com/" target='_blank' rel='noreferrer noopener'> NimbaTube.com</a>  et gagnez des NimbaCoins, les jetons BEP 20 de nouvelle génération déployés sur la blockchain révolutionnaire et intelligente Binance.</p>
                        <div className="button-flex text-center justify-content-center">
                  {/* <button className="btn-coummunity mr-2" type="submit">
                    join ico{" "}
                  </button> */}
                  <a href="https://bscscan.com/token/0x2f8A45dE29bbfBB0EE802B340B4f91af492C6DE7?a=0x2f8A45dE29bbfBB0EE802B340B4f91af492C6DE7" target='_blank' rel='noreferrer noopener' className="btn-coummunity ml-2 mt-20" type="submit">
                  ADRESSE DU CONTRAT {" "}
                  </a>
                </div>
                    </div>
                </div>
            </div>
        </section>
    </header>

        </>
    )
}
export default Header